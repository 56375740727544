import React, { Component, createRef } from "react";
import { Category } from "../types";
import Menudropdown from "./MenuDropdown";

type CatagoriesDropdownProps = {
  Categories?: Array<Category>;
  currentCategoryId: String;
};

interface IState {
  showDropdown: boolean;
}

export class CategoriesDropdown extends Component<
  CatagoriesDropdownProps,
  IState
> {
  state = {
    showDropdown: false
  };

  private myRef = createRef<HTMLDivElement>();

  private clickHandler = (e: any) => {
    const node = this.myRef.current;
    const target = e.target as Element;
    if (!!node) {
      if (!node.contains(target)) {
        this.setState({ showDropdown: false });
      }
    }
  };

  componentDidMount() {
    document.addEventListener("pointerdown", this.clickHandler);
  }

  componentWillUnmount() {
    document.removeEventListener("pointerdown", this.clickHandler);
  }
  public handleToggleDropdown = (pState?: boolean) => {
    if (pState !== undefined) {
      this.setState({ showDropdown: pState });
    }
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  render() {
    const collection = this.props.Categories;
    const currentEntryId = this.props.currentCategoryId;
    const getFirstServiceInCategory = (pCatId?: string) => {
      /* get first service in category*/
      let path = "";
      if (collection !== undefined) {
        const category = collection.find(cat => cat.id === pCatId);
        if (category !== undefined) {
          if (category.services && category.services.length > 0) {
            const services = category.services;
            if (services !== undefined) {
              const app = services[0];
              if (app.versions !== undefined) {
                const version = app.versions[app.versions.length - 1];
                if (version.sections !== undefined) {
                  const section = version.sections[0];
                  path =
                    pCatId + "/" + app.id + "/" + version.id + "/" + section.id;
                }
              } else {
                const section = app.sections[0];
                path = pCatId + "/" + app.id + "/latest/" + section.id;
              }
            }
          }
        }
      }
      return path;
    };
    return (
      <Menudropdown
        collection={collection}
        currentEntryId={currentEntryId}
        helperFunction={getFirstServiceInCategory}
      />
    );
  }
}

export default CategoriesDropdown;
