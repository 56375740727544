import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import CodeBlock from "./CodeBlock";
import styled from "styled-components";
import { Colors } from "../../../styles/index";
import gfm from "remark-gfm";

export class Markdown extends Component {
  render() {
    const { categoryId, appId, sectionId, versionId } = this.props;
    const version = versionId && versionId !== "latest" ? `/${versionId}` : "";
    const input =
      "# Could not load markdown\n\nPlease take a look at the logs to see what has happened";
    return (
      <Frame style={{ marginBottom: 40 }}>
        <ReactMarkdown
          components={CodeBlock}
          plugins={[gfm]}
          transformImageUri={uri =>
            `/docs/${categoryId}/${appId}${version}/${sectionId}/${uri}`
          }
        >{this.props.markdown || input}</ReactMarkdown>
      </Frame>
    );
  }
}

const Frame = styled.div`
  padding-bottom: 20px;

  h1 {
    color: ${Colors.global.fontColors.pronounced};
  }

  img {
    max-width: 100%;
    background-color: #ffffff;
  }

  p > code,
  a,
  .HighlightColor {
    color: ${Colors.global.fontColors.pronounced};
  }

  code > span {
    background: transparent !important;
  }

  * {
    color: ${Colors.global.fontColors.primary};
  }
    table {
        border-collapse: collapse;

    }
    th, td {
        border: 1px solid black;
        padding: 5px;
        text-align: left;
    }

    th {
        background-color: ${Colors.global.tableHeaderBackground};
    }
`;

export default Markdown;
