import React, { Component } from "react";
import styled from "styled-components";
import { Category } from "../types";
import { CardComponent } from "./Card";

type CategorySectionProps = {
  displayName: string;
  category: Category;
  searchTerm: string;
};

export class GetStarted extends Component<CategorySectionProps> {
  render() {
    const { category, searchTerm } = this.props;
    const categoryIcon = category.icon;
    const filteredApps = category.services
      ? category.services.filter(elem =>
          elem.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : [];

    return (
      <Frame
        style={{
          order: 1,
          display: filteredApps.length === 0 ? "none" : "initial"
        }}
      >
        {filteredApps.length === 0
          ? ""
          : filteredApps.map(app => {
              const icon = app.icon || categoryIcon;
              const latestVersion =
                (app.versions || []).length > 0
                  ? app.versions.slice(-1)[0]
                  : undefined;
              const sections = latestVersion
                ? latestVersion.sections
                : app.sections;
              const firstSection = sections ? sections[0] : undefined;
              const appendUrl =
                latestVersion && firstSection
                  ? `${latestVersion.id}/${firstSection.id}`
                  : firstSection
                  ? `latest/${firstSection.id}`
                  : "";
              return (
                <CardComponent
                  key={app.id}
                  icon={icon}
                  text={app.name}
                  description={app.description}
                  url={`/${category.id}/${app.id}/${appendUrl}`}
                  elipsisCutOff={120}
                />
              );
            })}
      </Frame>
    );
  }
}

const Frame = styled.div`
  width: 100%;
  margin-bottom: 50px;
`;

export default GetStarted;
