import { Colors } from "../../../styles";
import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledLink = styled(Link)`
  font-family: "Montserrat" !important;
  text-decoration: none;
  color: ${Colors.navigationHeader.dropdown.fontColor};
  margin: 5px;
  margin-top: 0px;
  &.active {
    color: ${Colors.navigationHeader.dropdown.fontColor};
  }
`;

export const Frame = styled.div``;

export const NavDropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: inherit;
  position: absolute;
  margin-top: 60px;
`;

export const ListElem = styled.li`
  list-style-type: none;
  border-left: 4px solid transparent;
  &.active {
    border-left: 4px solid
      ${Colors.navigationHeader.dropdown.activeBorderLeftColor};
    background-color: ${Colors.navigationHeader.dropdown.activeBackgroundColor};
  }
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: ${Colors.navigationHeader.dropdown.backgroundColor};
  &:hover {
    background-color: ${Colors.navigationHeader.dropdown.hoverBackgroundColor};
  }

  &:hover ${StyledLink} {
    color: ${Colors.navigationHeader.dropdown.hoverFontColor};
  }
`;

export const HeaderComtainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 60px;
  border-left: 4px solid transparent;
  cursor: pointer;
  user-select: none;
  width: inherit;
`;

export const InnerFrame = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
`;

export const NavDropdown = styled.ul`
  height: max-content;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 0px;
  margin-top: 0px;
  z-index: 999;
  &.visible {
    display: initial;
  }

  &.hidden {
    display: none;
  }
`;

export const TextContent = styled.div`
  font-size: 16px;
  line-height: 150%;
  margin: auto;
  margin-right: 0px;
  margin-left: 0px;
  width: 100%;
  font-family: "Montserrat" !important;
  /* identical to box height, or 24px */
  color: ${Colors.navigationHeader.fontColor};
`;
