import React, { Component } from "react";
import styled from "styled-components";
import SearchIcon from "../../icons/Search";
import { Colors } from "../../../styles";

type SearchHeaderProps = {
  onChange: (val: string) => any;
};

export class SearchHeader extends Component<SearchHeaderProps> {
  render() {
    return (
      <OuterFrame>
        <Frame>
          <SearchIcon color={Colors.search.iconColor} />
          <Input
            onChange={e => this.props.onChange(e.target.value)}
            placeholder="Search"
          />
        </Frame>
      </OuterFrame>
    );
  }
}

const OuterFrame = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 60px;
`;

const Input = styled.input`
  font-family: "Avenir";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  border: none;
  margin-right: 10px;
  outline: none;
  flex-grow: 1;
  background: ${Colors.search.backgroundColor};
  width: -moz-available;
  ::placeholder {
    color: ${Colors.search.placeholderTextColor};
  }
`;

const Frame = styled.div`
  width: 40vw;
  height: 60px;
  background: ${Colors.search.backgroundColor};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  border: 1px solid ${Colors.search.borderColor};
  box-sizing: border-box;
  border-radius: 51px;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export default SearchHeader;
