import React, { Component } from "react";
import styled from "styled-components";
import { Colors } from "../../../styles";
import { Category } from "../types";
import { CardComponent } from "./Card";

type CategorySectionProps = {
  displayName: string;
  category: Category;
  searchTerm: string;
};

export class CategorySection extends Component<CategorySectionProps> {
  render() {
    const { category, displayName, searchTerm } = this.props;
    const categoryIcon = category.icon;
    const filteredApps = category.services
      ? category.services.filter(elem =>
          elem.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : [];

    return (
      <Frame
        style={{
          order: 2,
          display: filteredApps.length === 0 ? "none" : "initial"
        }}
      >
        <CustomHeadingH3
          style={{
            color: Colors.FontSecondaryColor,
            display: filteredApps.length === 0 ? "none" : "inherit"
          }}
        >
          {displayName}
        </CustomHeadingH3>
        <Grid className={filteredApps.length === 1 ? "col-1" : "col-2"}>
          {filteredApps.length === 0
            ? ""
            : filteredApps.map(app => {
                const icon = app.icon || categoryIcon;
                const latestVersion =
                  (app.versions || []).length > 0
                    ? app.versions.slice(-1)[0]
                    : undefined;
                const sections = latestVersion
                  ? latestVersion.sections
                  : app.sections;
                const firstSection = sections ? sections[0] : undefined;
                const appendUrl =
                  latestVersion && firstSection
                    ? `${latestVersion.id}/${firstSection.id}`
                    : firstSection
                    ? `latest/${firstSection.id}`
                    : "";
                return (
                  <CardComponent
                    key={app.id}
                    icon={icon}
                    text={app.name}
                    description={app.description}
                    url={`/${category.id}/${app.id}/${appendUrl}`}
                    elipsisCutOff={40}
                  />
                );
              })}
        </Grid>
      </Frame>
    );
  }
}

const Grid = styled.div`
  display: grid;

  &&.col-1 {
    grid-template-columns: 1fr;
  }

  &&.col-2 {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  @media (max-width: 1024px) {
    grid-template-columns: auto !important;
  }
`;

const Frame = styled.div`
  width: 100%;
  margin-bottom: 50px;
`;

export const CustomHeadingH3 = styled.p`
  color: ${Colors.global.fontColors.categoryHeader};
  text-align: center;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  margin-top: 15px !important;
`;

export default CategorySection;
