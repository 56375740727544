import React, { Component } from "react";
import styled from "styled-components";
import CategorySection from "./CategorySection";
import GetStarted from "./GetStarted";
import { Documentation } from "../types";
import SearchHeader from "../navigation/SearchHeader";
import Spinner from "../../InboxSpinner";

type MenuProps = {
  documentation: Documentation;
};

type MenuState = {
  searchTerm: string;
};

export class Menu extends Component<MenuProps, MenuState> {
  state = { searchTerm: "" };

  render() {
    return (
      <Frame>
        <SearchHeader onChange={searchTerm => this.setState({ searchTerm })} />
        {this.props.documentation.categories ? (
          this.props.documentation.categories.map(category => {
            return category.id === "getting-started" ? (
              <GetStarted
                key={category.id}
                searchTerm={this.state.searchTerm}
                displayName={category.name}
                category={category}
              />
            ) : (
              <CategorySection
                key={category.id}
                searchTerm={this.state.searchTerm}
                displayName={category.name}
                category={category}
              />
            );
          })
        ) : (
          <div style={{ textAlign: "center" }}>
            <Spinner />
          </div>
        )}
      </Frame>
    );
  }
}

const Frame = styled.div`
  height: 100%;
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 0px 10px 20px 10px;
  }
`;

export default Menu;
