import React, { Component, createRef } from "react";
import { Section } from "../types";
import styled from "styled-components";

import Menudropdown from "./MenuDropdown";

type SectionsDropdownProps = {
  Sections?: Array<Section | any>;
  currentSectionId?: String;
  baseURL: String;
};

interface IState {
  showDropdown: boolean;
}

export class SectiondDropdown extends Component<SectionsDropdownProps, IState> {
  state = {
    showDropdown: false
  };

  private myRef = createRef<HTMLDivElement>();

  private clickHandler = (e: any) => {
    const node = this.myRef.current;
    const target = e.target as Element;
    if (!!node) {
      if (!node.contains(target)) {
        this.setState({ showDropdown: false });
      }
    }
  };

  componentDidMount() {
    document.addEventListener("pointerdown", this.clickHandler);
  }

  componentWillUnmount() {
    document.removeEventListener("pointerdown", this.clickHandler);
  }

  public handleToggleDropdown = (pState?: boolean) => {
    if (pState !== undefined) {
      this.setState({ showDropdown: pState });
    }
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  render() {
    const collection = this.props.Sections;
    const currentEntryId = this.props.currentSectionId;
    const baseURL = this.props.baseURL;

    const getFirstSectionInService = (pServiceId?: string) => {
      return baseURL + "/" + pServiceId;
    };
    return (
      <Menudropdownwrapper>
        <Menudropdown
          collection={collection}
          currentEntryId={currentEntryId}
          baseURL={this.props.baseURL}
          helperFunction={getFirstSectionInService}
          hideSlashIcon={true}
        />
      </Menudropdownwrapper>
    );
  }
}

const Menudropdownwrapper = styled.span`
  @media (max-width: 768) {
    max-width: 200px !important;
  }
`;

export default SectiondDropdown;
