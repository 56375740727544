import React, { Component, createRef } from "react";
import { Category } from "../types";
import Menudropdown from "./MenuDropdown";

type ServicesDropdownProps = {
  Services?: Array<Category | any>;
  currentServiceId: String;
  baseURL: String;
};

interface IState {
  showDropdown: boolean;
}

export class ServicesDropdown extends Component<ServicesDropdownProps, IState> {
  state = {
    showDropdown: false
  };

  private myRef = createRef<HTMLDivElement>();

  private clickHandler = (e: any) => {
    const node = this.myRef.current;
    const target = e.target as Element;
    if (!!node) {
      if (!node.contains(target)) {
        this.setState({ showDropdown: false });
      }
    }
  };

  componentDidMount() {
    document.addEventListener("pointerdown", this.clickHandler);
  }

  componentWillUnmount() {
    document.removeEventListener("pointerdown", this.clickHandler);
  }

  public handleToggleDropdown = (pState?: boolean) => {
    if (pState !== undefined) {
      this.setState({ showDropdown: pState });
    }
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  render() {
    const collection = this.props.Services;
    const currentEntryId = this.props.currentServiceId;
    const baseURL = this.props.baseURL;

    const getFirstSectionInService = (pServiceId?: string) => {
      let path;
      if (collection !== undefined) {
        const service = collection.find(ser => ser.id === pServiceId);
        if (service !== undefined) {
          if (service.versions !== undefined) {
            const version = service.versions[service.versions.length - 1];
            if (version.sections !== undefined) {
              const section = version.sections[0];
              path =
                baseURL +
                "/" +
                service.id +
                "/" +
                version.id +
                "/" +
                section.id;
            }
          } else {
            const section = service.sections[0];
            path = baseURL + "/" + service.id + "/latest/" + section.id;
          }
        }
      }
      return path;
    };
    return (
      <Menudropdown
        collection={collection}
        currentEntryId={currentEntryId}
        helperFunction={getFirstSectionInService}
        baseURL={baseURL}
      />
    );
  }
}
export default ServicesDropdown;
