import React from "react";
import { Colors } from "../../styles";

export default class ChevronRight extends React.Component {
  render() {
    return (
      <span style={{ margin: "auto", minHeight: "20px", minWidth: "20px" }}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          transform={this.props.transform}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5859 10.0001L7.29297 6.70718L8.70718 5.29297L13.4143 10.0001L8.70718 14.7072L7.29297 13.293L10.5859 10.0001Z"
            fill={this.props.fill || Colors.global.chevronColor}
          />
        </svg>
      </span>
    );
  }
}
