import React, { Component } from "react";
import styled from "styled-components";
import SwaggerUI from "swagger-ui-react";
import { Colors } from "../../../styles";
import "swagger-ui-react/swagger-ui.css";

export class Swagger extends Component {
  render() {
    return (
      <Frame>
        <SwaggerUI docExpansion={"list"} url={this.props.swagger} />
      </Frame>
    );
  }
}

const Frame = styled.div`
  background-color: ${Colors.swagger.backgroundColor};
  border-radius: 3px;
  margin-bottom: 40px;
  box-shadow: 0px 2px 20px ${Colors.swagger.shadowColor};
  border-radius: 4px;
  padding: 10px 0px 10px 0px;
  margin-top: 5vh;

  * {
    color: ${Colors.swagger.fontColors.primary} !important;
  }

  .opblock-section-header,
  textarea {
    background-color: ${Colors.swagger.sectionHeaderBackgroundColor} !important;
  }

  textarea,
  div.response-col_description__inner > div > p
  {
    color: ${Colors.swagger.fontColors.primary} !important;
  }

  pre * {
    font-family: "IBM Plex Mono" !important;
    color: ${Colors.swagger.fontColors.code} !important;
  }
`;

export default Swagger;
