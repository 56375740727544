import React, { Component } from "react";
import styled from "styled-components";
import { Category, App, Documentation, Section } from "../types";
import Markdown from "./Markdown";
import Swagger from "./Swagger";
import VersionsDropdown from "../navigation/VersionsDropdown";
import CategoriesDropdown from "../navigation/CategoriesDropdown";
import ServicesDropdown from "../navigation/ServicesDropdown";
import SectionsDropdown from "../navigation/SectionsDropdown";

import Spinner from "../../InboxSpinner";
import { Colors } from "../../../styles";

type DocumentationMonitorProps = {
  match: {
    params: {
      appId: string;
      categoryId: string;
      versionId?: string;
      sectionId?: string;
      categoryName?: string;
    };
  };
  documentation: Documentation;
  history: {
    push: (path: string) => any;
  };
  active: string;
};

type DocumentationMonitorState = {
  markdown: any;
  swagger: any;
  currentVersions: Array<String>;
  sections: Array<Section>;
  loading: boolean;
  version?: string;
};

export class DocumentationMonitor extends Component<
  DocumentationMonitorProps,
  DocumentationMonitorState
> {
  markdown: any = "";
  state = {
    currentVersions: [],
    markdown: undefined,
    swagger: undefined,
    file: undefined,
    loading: false,
    sections: [],
    version: undefined
  };

  render() {
    const { categoryId, appId, sectionId, versionId } = this.props.match.params;
    const { swagger, markdown, loading, sections } = this.state;
    const categories = this.props.documentation.categories;
    const category: Category | undefined =
      this.props.documentation.categories &&
      this.props.documentation.categories.find(cat => cat.id === categoryId);
    const services: Array<App> | undefined = category
      ? category.services
      : undefined;
    const app: App | undefined = category
      ? category.services && category.services.find(app => app.id === appId)
      : undefined;
    const versions = app && versionId ? app.versions : "";
    const version =
      app && versionId && versions && versions.find(v => v.id === versionId);

    const sectionNames =
      (version && version.sections) || (app && app.sections) || [];
    const section: Section | undefined =
      sectionNames &&
      (sectionId
        ? sectionNames.find(s => s.id === sectionId)
        : sectionNames[0]);

    return (
      <Frame>
        <InnerFrameHeader>
          <Navigation>
            {sections && (
              <SpanParent
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "77px"
                }}
              >
                <SpanWrapper>
                  <CategoriesDropdown
                    Categories={categories}
                    currentCategoryId={categoryId}
                  />
                </SpanWrapper>
                <SpanWrapper>
                  <ServicesDropdown
                    Services={services}
                    currentServiceId={appId}
                    baseURL={categoryId}
                  />
                </SpanWrapper>
                <SectionsDropdown
                  Sections={sections}
                  currentSectionId={sectionId}
                  baseURL={categoryId + "/" + appId + "/" + versionId}
                />

                {this.state.currentVersions && (
                  <VerionsWrapper>
                    <VersionsDropdown
                      title={this.state.version}
                      list={this.state.currentVersions}
                      categoryId={categoryId}
                      appId={appId}
                      sectionId={sectionId}
                      onChange={this.handleUpdateVersion}
                    />
                  </VerionsWrapper>
                )}
              </SpanParent>
            )}
          </Navigation>
        </InnerFrameHeader>
        <InnerFrame>
          {markdown && !loading && (
            <Markdown
              markdown={markdown}
              categoryId={categoryId}
              appId={appId}
              sectionId={section && section.id}
              versionId={versionId}
            />
          )}
          {swagger && !loading && <Swagger swagger={swagger} />}
          {loading && (
            <div style={{ textAlign: "center", marginTop: 10 }}>
              <Spinner />
            </div>
          )}
        </InnerFrame>
      </Frame>
    );
  }

  handleUpdateVersion = (
    categoryId: string,
    appId: string,
    versionId?: string,
    sectionId?: string
  ) => {
    this.props.history.push(
      `/${categoryId}/${appId}/${versionId}/${sectionId || ""}`
    );
    this.updateSections(appId, categoryId, versionId);
  };

  async updateSections(
    appId: string,
    categoryId: string,
    versionId?: string,
    newCategories?: Category[],
    section?: string
  ) {
    versionId = versionId === "latest" ? undefined : versionId;
    const categories = newCategories || this.props.documentation.categories;
    const category: Category | undefined =
      categories && categories.find(cat => cat.id === categoryId);
    const app: App | undefined = category
      ? category.services && category.services.find(app => app.id === appId)
      : undefined;
    const versionIndex =
      app && versionId && app.versions
        ? app.versions.findIndex(ver => ver.id === versionId)
        : undefined;
    const version =
      app &&
      app.versions &&
      (versionIndex || versionIndex === 0
        ? app.versions[versionIndex]
        : app.versions.slice(-1)[0]);
    const sections =
      (version && version.sections) || (app && app.sections) || [];
    this.setState({
      sections: sections || [],
      currentVersions: app
        ? app.versions && app.versions.map(ver => ver.id)
        : this.state.currentVersions,
      version: version ? version.id : this.state.version,
      loading: this.state.sections ? true : false
    });

    if (sections.length > 0) {
      this.updateFile(sections, section || sections[0].id);
    }
  }

  async updateFile(sections: Array<Section>, id: string) {
    const section = sections && sections.find(section => section.id === id);
    const externalSwaggerUrl =
      section && section.externalSwaggerRef && section.externalSwaggerRef.url;
    const files = section && section.files;
    const file = files && files[0];
    const swaggerUrl = externalSwaggerUrl
      ? externalSwaggerUrl
      : file
      ? file.path
      : null;

    if (file && file.type === "markdown") {
      const markdown = await readTextFile(file.path);
      this.setState({ markdown, swagger: null, loading: false });
    } else if (
      (file && (file.type === "swagger" || file.type === "openapi")) ||
      externalSwaggerUrl
    ) {
      this.setState({ swagger: swaggerUrl, markdown: null, loading: false });
    } else {
      this.setState({ swagger: null, markdown: null, loading: false });
    }
  }

  componentDidMount() {
    const { appId, categoryId, versionId, sectionId } = this.props.match.params;
    this.updateSections(
      appId,
      categoryId,
      versionId,
      this.props.documentation.categories,
      sectionId
    );
  }

  componentWillReceiveProps(nextProps: DocumentationMonitorProps) {
    const { appId, categoryId, versionId, sectionId } = nextProps.match.params;
    const {
      appId: appIdOld,
      categoryId: categoryIdOld,
      versionId: versionOld,
      sectionId: sectionIdOld
    } = this.props.match.params;
    const newlyPopulatedDoc =
      nextProps.documentation.categories !== undefined &&
      this.props.documentation.categories === undefined;

    if (
      appId !== appIdOld ||
      categoryId !== categoryIdOld ||
      versionId !== versionOld ||
      sectionId !== sectionIdOld ||
      newlyPopulatedDoc
    ) {
      this.setState({ loading: true, swagger: null, markdown: null });
      this.updateSections(
        appId,
        categoryId,
        versionId,
        nextProps.documentation.categories,
        sectionId
      );
    }
  }
}

export function readTextFile(file: any) {
  return new Promise((resolve, reject) => {
    var rawFile = new XMLHttpRequest();
    rawFile.open("GET", file, true);
    rawFile.onload = () => {
      if (rawFile.readyState === 4) {
        if (rawFile.status === 200 || rawFile.status === 0) {
          const allText = rawFile.responseText;
          return resolve(allText);
        }
      }
      reject("Failed to fetch file");
    };
    rawFile.send(null);
  });
}

const Frame = styled.div`
  width: 100%;
  position: relative;
`;

const InnerFrameHeader = styled.div`
  width: 100%;
  max-height: 60px;
  top: 0;
  position: sticky;
  border-bottom: 1px solid ${Colors.navigationHeader.borderBottomColor};
  box-sizing: border-box;
  display: flex;
  background-color: white;
  z-index: 1000;
`;

const InnerFrame = styled.div`
  width: 70%;
  position: relative;
  margin: 0 auto;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 0px 10px 0px 10px;
    box-sizing: border-box;
  }
`;

const Navigation = styled.div`
  width: 100%;
`;

const SpanWrapper = styled.span`
  @media (max-width: 1024px) {
    display: none !important;
  }
`;

const SpanParent = styled.span`
  @media (max-width: 1024px) {
    margin-left: 15px !important;
  }
`;

const VerionsWrapper = styled.span`
  margin: 0px 50px 0px auto;
  @media (max-width: 1024px) {
    margin-right: 15px !important;
  }
`;

export default DocumentationMonitor;
