import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import { Colors } from "../../../styles/index";

export class Landingpage extends Component {
  render() {
    return (
      <Frame>
        <ReactMarkdown
          source={this.props.markdown}
          renderers={{
            img: Image
          }}
        />
      </Frame>
    );
  }
}

const Frame = styled.div`
  background-color: ${Colors.global.backgroundColor};
  text-align: center;
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 3vw;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  max-width: 30vw;
  margin: auto !important;

  p:first-of-type {
    width: 100%;
    height: 100%;
    max-width: 20em;
    margin: auto;
  }

  img {
    width: 100%;
    height: 100%;
  }

  p > code,
  a,
  .HighlightColor {
    color: ${Colors.global.fontColors.pronounced};
  }

  code > span {
    background: transparent !important;
  }

  * {
    color: ${Colors.global.fontColors.primary};
  }

  h1 {
    color: ${Colors.global.fontColors.pronounced};
  }

  @media (max-width: 1024px) {
    padding: 10px;

    img {
      max-width: 50vw;
    }
    max-width: none !important;
    margin: 10px;
  }

  @media (max-height: 740px) {
    h1 {
      display: none;
    }
  }
`;

export default Landingpage;
