import React from "react";
import styled from "styled-components";
import Main from "./components/main";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Oidc from "oidc-client";
import { oidc } from "./config";
import Spinner from "./components/InboxSpinner";
import { GlobalStyle } from "./styles/GlobalStyle";

const LoginCallbackPage = () => {
  new Oidc.UserManager(oidc)
    .signinRedirectCallback()
    .then(user => {
      window.location.replace(
        window.sessionStorage.getItem("redirectUrl") || "/"
      );
      window.sessionStorage.removeItem("redirectUrl");
    })
    .catch(function(e) {
      console.error(e);
    });
  return <Spinner />;
};

const SilentCallbackPage = () => {
  new Oidc.UserManager(oidc)
    .signinSilentCallback()
    .then(user => {
      window.location.replace("/");
    })
    .catch(function(e) {
      console.error(e);
    });
  return <Spinner />;
};

class App extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    new Oidc.UserManager(oidc).getUser().then(user => {
      this.setState({ user });
    });
  }

  render() {
    return (
      <>
        <GlobalStyle />
        <Router>
          <Switch>
            <Route path="/callback" component={LoginCallbackPage} />
            <Route
              exact
              path="/callback-silent"
              component={SilentCallbackPage}
            />
            <Route component={MainComponent}></Route>
          </Switch>
          <GlobalStyle />
        </Router>
      </>
    );
  }
}

const MainComponent = () => (
  <Frame>
    <Main />
  </Frame>
);

const Frame = styled.div`
  height: 100vh;
  overflow: hidden;
`;

export default App;
