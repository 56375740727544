import React from "react";

export default class Search extends React.Component {
  render() {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 11C17 14.3137 14.3137 17 11 17C7.68629 17 5 14.3137 5 11C5 7.68629 7.68629 5 11 5C14.3137 5 17 7.68629 17 11Z"
          stroke={this.props.color}
          strokeWidth="2"
        />
        <path d="M15 15L19.5 19.5" stroke={this.props.color} strokeWidth="2" />
      </svg>
    );
  }
}
