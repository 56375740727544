import React, { Component, createRef } from "react";
import styled from "styled-components";
import { Colors } from "../../../styles";
import ChevronRightIcon from "../../icons/ChevronRight";
import { TextContent, NavDropdown, ListElem } from "./DropdownComponents";

type DropdownProps = {
  list?: Array<any>;
  title?: string;
  categoryId: string;
  appId: string;
  sectionId?: string;
  onChange: any;
};

interface IState {
  showDropdown: boolean;
}

export class Dropdown extends Component<DropdownProps, IState> {
  state = {
    showDropdown: false
  };

  private myRef = createRef<HTMLDivElement>();

  private clickHandler = (e: any) => {
    const node = this.myRef.current;
    const target = e.target as Element;
    if (!!node) {
      if (!node.contains(target)) {
        this.setState({ showDropdown: false });
      }
    }
  };

  componentDidMount() {
    document.addEventListener("pointerdown", this.clickHandler);
  }

  componentWillUnmount() {
    document.removeEventListener("pointerdown", this.clickHandler);
  }

  public handleToggleDropdown = (pState?: boolean) => {
    if (pState !== undefined) {
      this.setState({ showDropdown: pState });
    }
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  render() {
    const { title, list, categoryId, appId, sectionId } = this.props;

    return (
      <FrameModified ref={this.myRef}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "auto",
            height: "60px",
            cursor: "pointer",
            userSelect: "none",
            width: "100%"
          }}
        >
          <Container onPointerDown={() => this.handleToggleDropdown()}>
            <TextContent style={{ marginLeft: "5px" }}>{title}</TextContent>
            <ChevronRightIcon transform={"rotate(90)"} />
          </Container>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "auto",
            width: "100%"
          }}
        >
          <NavDropdownStyled
            style={{
              display:
                list !== undefined && list.length > 1 && this.state.showDropdown
                  ? "initial"
                  : "none"
            }}
          >
            {list
              ? list
                  .sort(function(a, b) {
                    return b - a;
                  })
                  .map(entry =>
                    entry === title ? (
                      <Li
                        onClick={() => {
                          this.props.onChange(
                            categoryId,
                            appId,
                            entry,
                            sectionId
                          );
                        }}
                        key={entry.id}
                        className={"active"}
                      >
                        {entry}
                      </Li>
                    ) : (
                      <Li
                        onClick={() => {
                          this.props.onChange(
                            categoryId,
                            appId,
                            entry,
                            sectionId
                          );
                        }}
                        key={entry.id}
                      >
                        {entry}
                      </Li>
                    )
                  )
              : "undefined"}
          </NavDropdownStyled>
        </div>
      </FrameModified>
    );
  }
}

const Li = styled(ListElem)`
  cursor: pointer;
  text-decoration: none;
  margin-top: 0px;
  padding-left: 5px;
  color: ${Colors.navigationHeader.dropdown.fontColor};
  &.active {
    color: ${Colors.navigationHeader.dropdown.activeFontColor};
  }

  &:hover {
    color: ${Colors.navigationHeader.dropdown.hoverFontColor};
  }
`;

const Frame = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Container = styled.span`
  height: min-content;
  display: flex;
  margin: auto;
  border-radius: 3px;
  border: 2px solid ${Colors.navigationHeader.slashColor};
  padding: 5px;
  width: 100%;
`;

const NavDropdownStyled = styled(NavDropdown)`
  width: 100%;

  &.disable {
    display: none;
    cursor: default;
  }
`;

const FrameModified = styled(Frame)`
  display: flex;
  flex-direction: column;
  width: 80px;
  @media (max-width: 1024px) {
    width: 50px;
  }
`;

export default Dropdown;
