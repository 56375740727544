import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import Oidc from "oidc-client";
import Content from "./content";
import { Documentation } from "./types";
import { authenticationEnabled, oidc } from "../../config";
import Spinner from "../InboxSpinner";
import { Colors } from "../../styles";

import { Landingpage } from "../main/content/LandingPage";
import { readTextFile } from "../main/content/DocumentationMonitor";

const challengeIdentity = () => {
  new Oidc.UserManager(oidc).signinRedirect().catch(function (e) {
    console.error(e);
  });
};

type MainProps = {};
type MainState = {
  active: string;
  documentation?: Documentation;
  markdown?: any;
  user?: any;
  isLoading: boolean;
  showNavigation: boolean;
  title: string;
  companyName: string;
};

export class Main extends Component<MainProps, MainState> {
  state = {
    active: "api",
    documentation: {},
    markdown: null,
    user: null,
    isLoading: true,
    showNavigation: true,
    title: "",
    companyName: "",
  };

  async componentDidMount() {
    if (authenticationEnabled) {
      new Oidc.UserManager(oidc).getUser().then((user) => {
        this.setState({ user });
        setTimeout(() => {
          const { user } = this.state;
          if (!user) {
            readTextFile("/docs/index.md")
              .then((success) => {
                this.setState({ markdown: success, isLoading: false });
              })
              .catch((error) => {
                console.log("landing page not specified");
                window.sessionStorage.setItem(
                  "redirectUrl",
                  window.location.pathname
                );
                challengeIdentity();
              });
          } else {
            this.loadDocumentation();
          }
        }, 500);
      });
    } else {
      await this.loadDocumentation();
    }
  }

  async loadDocumentation() {
    const user: any = this.state.user;
    const headers = authenticationEnabled
      ? { Authorization: `Bearer ${user.access_token}` }
      : null;

    try {
      const { data } = await axios.get("/docs/config.json");
      this.setState({
        title: data.headerTitle,
        companyName: data.companyName,
      });
    } catch (err) {
      err.response && err.response.status === 404
        ? console.warn("No config.json")
        : console.error(err.message);
    }

    try {
      const { data } = await axios.get("/docs/index", { headers });
      this.setState({ documentation: data, isLoading: false });
    } catch (err) {
      if (
        err.response &&
        err.response.status === 401 &&
        authenticationEnabled
      ) {
        await readTextFile("docs/index.md")
          .then((success) => {
            this.setState({ markdown: success, isLoading: false });
          })
          .catch((error) => {
            console.log("landing page not specified");
            window.sessionStorage.setItem(
              "redirectUrl",
              window.location.pathname
            );
            challengeIdentity();
          });
      }
      throw err;
    }
  }

  render() {
    const { isLoading } = this.state;
    if (isLoading) {
      return (
        <Frame>
          <LoadingScreen>
            <Spinner size={80} />
          </LoadingScreen>
        </Frame>
      );
    }

    return (
      <Frame>
        <HorizontalHeaderBar>
          <IconWrapper>
            <NavLink to={"/"}>
              <img
                src="/docs/icon.svg"
                alt="icon.svg"
                style={{
                  width: Colors.header.logoWidth,
                  height: Colors.header.logoHeight,
                }}
              />
            </NavLink>
          </IconWrapper>

          <CompanyName
            style={{ display: !!this.state.companyName ? "initial" : "none" }}
          >
            {this.state.companyName}
          </CompanyName>
          <TextContent>{this.state.title}</TextContent>
          <LoginButton
            style={{ display: !!this.state.markdown ? "initial" : "none" }}
            onClick={() => {
              window.sessionStorage.setItem(
                "redirectUrl",
                window.location.pathname
              );
              challengeIdentity();
            }}
          >
            Login
          </LoginButton>
        </HorizontalHeaderBar>
        <InnerFrame>
          <ContentFrame>
            <ContentInnerFrame>
              {!!this.state.markdown ? (
                <Landingpage markdown={this.state.markdown || ""} />
              ) : (
                <Content
                  active={this.state.active}
                  documentation={this.state.documentation}
                />
              )}
            </ContentInnerFrame>
          </ContentFrame>
        </InnerFrame>
      </Frame>
    );
  }

  showHideNavigation = () => {
    this.setState({
      showNavigation: !this.showHideNavigation,
    });
  };
}

const LoginButton = styled.div`
  margin: auto;
  margin-right: 22px;
  font-family: Avenir;
  background-color: ${Colors.header.descriptionColor};
  color: ${Colors.header.backgroundColor};
  border-radius: 4px;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  align-items: center;
  text-align: center;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
`;

const HorizontalHeaderBar = styled.div`
  max-height: 60px;
  height: 60px;
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid ${Colors.header.borderBottomColor};
  background-color: ${Colors.header.backgroundColor};
  display: flex;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  position: relative;
`;

const TextContent = styled.p`
  color: ${Colors.header.descriptionColor};
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 18.5px;
  line-height: 23px;
`;

const CompanyName = styled.p`
  color: ${Colors.header.companyNameColor};
  margin: auto;
  margin-left: 0px;
  margin-right: 015px;
  display: none;
  font-family: "Montserrat Alternates";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  @media (max-width: 1024px) {
    display: none !important;
  }
`;

const IconWrapper = styled.span`
  margin-top: 12.5px;
  margin-bottom: 12.5px;
  margin-right: 15px;
  border-right: 1px solid;
  border-color: ${Colors.header.pipeColor};
  padding-left: 15px;
  padding-right: 15px;
`;

const Frame = styled.span`
  height: 100%;
  width: 100%;
`;

const InnerFrame = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const ContentInnerFrame = styled.div`
  width: 100%;
  min-width: 700px;
  height: 100%;
  display: flex;

  @media (max-width: 1024px) {
    min-width: 0px;
  }
`;

const ContentFrame = styled.div`
  height: calc(100% - 60px);
  flex-grow: 1;
  padding-bottom: 0px;
  background-color: ${Colors.global.backgroundColor};
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    align-items: flex-start;
  }

  blockquote {
    border-left: 5px solid #ccc;
    padding-left: 20px;
    @media (max-width: 1024px) {
      border-left: 2.5px solid #ccc !important;
      padding-left: 10px !important;
      margin-left: 0px !important;
    }
  }

  p,
  li {
    line-height: 1.5em;
  }
`;

const LoadingScreen = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.global.backgroundColor};
`;

export default Main;
