import React from "react";

export default class Slash extends React.Component {
  render() {
    return (
      <svg
        display={this.props.display}
        width="20"
        height={this.props.height}
        viewBox="0 0 20 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10 0H11.0435L1.04348 30H0L10 0Z" fill={this.props.fill} />
      </svg>
    );
  }
}
