import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Menu from "./Menu";
import DocumentationMonitor from "./DocumentationMonitor";
import { Documentation } from "../types";

type MarkdownProps = {
  active: string;
  documentation: Documentation;
};

type MarkdownState = {
  markdown: any;
};

export class Content extends Component<MarkdownProps, MarkdownState> {
  render() {
    return (
      <Switch>
        <Route
          path="/:categoryId/:appId/:versionId/:sectionId"
          render={props => (
            <DocumentationMonitor
              documentation={this.props.documentation}
              {...props}
              active={this.props.active}
            />
          )}
        />
        <Route
          path="/:categoryId/:appId/:versionId"
          render={props => (
            <DocumentationMonitor
              documentation={this.props.documentation}
              {...props}
              active={this.props.active}
            />
          )}
        />
        <Route
          path="/:categoryId/:appId"
          render={props => (
            <DocumentationMonitor
              documentation={this.props.documentation}
              {...props}
              active={this.props.active}
            />
          )}
        />
        <Route
          render={props => (
            <Menu documentation={this.props.documentation} {...props} />
          )}
        />
      </Switch>
    );
  }
}

export default Content;
