// components/codeblock.js
import React from "react"
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import { Colors } from "../../../styles/index";

const flatten = (text, child) => {
    return typeof child === 'string'
      ? text + child
      : React.Children.toArray(child.props.children).reduce(flatten, text);
  };
  
  /**
   * HeadingRenderer is a custom renderer
   * It parses the heading and attaches an id to it to be used as an anchor
   */
  const HeadingRenderer = props => {
    const children = React.Children.toArray(props.children);
    const text = children.reduce(flatten, '');
    const slug = text.toLowerCase().replace(/\ /g, '-');
    return React.createElement('h' + props.level, { id: slug, className: "anchor-element" }, props.children);
  };
  

const Renderer = {
  code({node, inline, className, children, ...props}) {
    const match = /language-(\w+)/.exec(className || '')
    const lang = match?.length ? match[0] : "JS";

    return !inline && lang ? (
    <SyntaxHighlighter 
      style={customStyle} 
      language={lang} 
      PreTag="div" {...props}>
      {String(children).replace(/\n$/, '')}
    </SyntaxHighlighter>

    ) : (
      <code className={className} {...props}>
        {children}
      </code>
    )
  },
  h1: HeadingRenderer,
  h2: HeadingRenderer,
  h3: HeadingRenderer,
  h4: HeadingRenderer,
  h5: HeadingRenderer,
  h6: HeadingRenderer,
}

const customStyle = {
  'code[class*="language-"]': {
    color: Colors.global.fontColors.primary,
    background: "none",
    fontFamily: "IBM Plex Mono",
    textAlign: "left",
    whiteSpace: "pre",
    wordSpacing: "normal",
    wordBreak: "normal",
    wordWrap: "normal",
    lineHeight: "1.5",
    MozTabSize: "4",
    OTabSize: "4",
    tabSize: "4",
    WebkitHyphens: "none",
    MozHyphens: "none",
    msHyphens: "none",
    hyphens: "none"
  },
  'pre[class*="language-"]': {
    color: "white",
    background: "#FDF5F5",
    fontFamily: "IBM Plex Mono",
    textAlign: "left",
    whiteSpace: "pre",
    wordSpacing: "normal",
    wordBreak: "normal",
    wordWrap: "normal",
    lineHeight: "1.5",
    MozTabSize: "4",
    OTabSize: "4",
    tabSize: "4",
    WebkitHyphens: "none",
    MozHyphens: "none",
    msHyphens: "none",
    hyphens: "none",
    padding: "1em",
    margin: ".5em 0",
    overflow: "auto",
    borderRadius: ".2em"
  },
  ':not(pre) > code[class*="language-"]': {
    background: "hsl(30, 20%, 25%)",
    padding: ".15em .2em .05em",
    borderRadius: ".3em",
    border: ".13em solid hsl(30, 20%, 40%)",
    boxShadow: "1px 1px .3em -.1em black inset",
    whiteSpace: "normal"
  },
  comment: {
    color: "hsl(30, 20%, 50%)"
  },
  prolog: {
    color: "hsl(30, 20%, 50%)"
  },
  doctype: {
    color: "hsl(30, 20%, 50%)"
  },
  cdata: {
    color: "hsl(30, 20%, 50%)"
  },
  punctuation: {
    Opacity: ".7"
  },
  ".namespace": {
    Opacity: ".7"
  },
  property: {
    color: Colors.global.fontColors.primary,
  },
  tag: {
    color: Colors.global.fontColors.pronounced,
  },
  boolean: {
    color: Colors.global.fontColors.pronounced,
  },
  number: {
    color: Colors.global.fontColors.pronounced,
  },
  constant: {
    color: Colors.global.fontColors.pronounced,
  },
  symbol: {
    color: Colors.global.fontColors.pronounced,
  },
  selector: {
    color: Colors.global.fontColors.pronounced,
  },
  "attr-name": {
    color: Colors.global.fontColors.pronounced,
  },
  string: {
    color: Colors.global.fontColors.pronounced,
  },
  char: {
    color: Colors.global.fontColors.pronounced,
  },
  builtin: {
    color: Colors.global.fontColors.pronounced,
  },
  inserted: {
    color: Colors.global.fontColors.pronounced,
  },
  operator: {
    color: Colors.global.fontColors.pronounced,
  },
  entity: {
    color: Colors.global.fontColors.pronounced,
  },
  url: {
    color: Colors.global.fontColors.pronounced,
  },
  ".language-css .token.string": {
    color: Colors.global.fontColors.pronounced,
  },
  ".style .token.string": {
    color: Colors.global.fontColors.pronounced,
  },
  variable: {
    color: Colors.global.fontColors.pronounced,
  },
  atrule: {
    color: Colors.global.fontColors.pronounced,
  },
  "attr-value": {
    color: Colors.global.fontColors.pronounced,
  },
  keyword: {
    color: Colors.global.fontColors.pronounced,
  },
  regex: {
    color: Colors.global.fontColors.pronounced,
  },
  important: {
    color: Colors.global.fontColors.pronounced,
  },
  bold: {
    fontWeight: "bold"
  },
  italic: {
    fontStyle: "italic"
  },
  deleted: {
    color: "red"
  }
};


export default Renderer