import React from "react";
import styled from "styled-components";
import ChevronRightIcon from "../../icons/ChevronRight";
import { Colors } from "../../../styles";
import { NavLink } from "react-router-dom";

export const CardComponent = (props: {
  icon?: string;
  text: string;
  description: string;
  url: string;
  elipsisCutOff: number;
}) => {
  return (
    <Card key={props.text} to={props.url}>
      {props.icon && (
        <img src={props.icon} alt="" style={{ minWidth: "50px" }} />
      )}
      <span>
        <CardHeading>{props.text}</CardHeading>
        <CardDescription>
          {props.description !== undefined
            ? ToolongAddElipsis(props.description, props.elipsisCutOff)
            : ""}
        </CardDescription>
      </span>
      <span style={{ marginLeft: "auto" }}>
        <ChevronRightIcon fill={Colors.navigationCards.chevronColor} />
      </span>
    </Card>
  );
};

function ToolongAddElipsis(pText: string, pCutOff: number) {
  var n = pCutOff;
  if (pText.length >= n) {
    return pText.substring(0, n).concat(" ...");
  }
  return pText;
}

const CardDescription = styled.p`
  font-size: 16px;
  line-height: 25px;
  font-family: "IBM Plex Sans";

  margin-left: 20px;
  margin-top: 0px;
  color: ${Colors.navigationCards.descriptionColor};
  @media (max-width: 1024px) {
    display: none;
  }
`;

const CardHeading = styled.p`
  margin-left: 20px;
  margin-bottom: 0px;
  color: ${Colors.navigationCards.headerColor};

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  @media (max-width: 1024px) {
    margin: auto !important;
    margin-left: 10px !important;
  }
`;

const Card = styled(NavLink)`
  height: 80px;
  flex-grow: 1;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: ${Colors.navigationCards.backgroundColor};
  text-decoration: none;

  &:hover {
    background-color: ${Colors.navigationCards.hoverBackgroundColor};
  }

  &:hover ${CardHeading} {
    color: ${Colors.navigationCards.hoverHeaderColor};
  }

  &:hover ${CardDescription} {
    color: ${Colors.navigationCards.hoverDescriptionColor};
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;

  @media (max-width: 1024px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  p {
    user-select: none;
  }

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;
