import React, { Component, createRef } from "react";
import { Colors } from "../../../styles";
import ChevronRightIcon from "../../icons/ChevronRight";
import SlashIcon from "../../icons/slash";
import {
  StyledLink,
  Frame,
  InnerFrame,
  HeaderComtainer,
  TextContent,
  NavDropdownContainer,
  NavDropdown,
  ListElem
} from "./DropdownComponents";

type DropdownProps = {
  collection?: Array<any | any>;
  currentEntryId?: String;
  baseURL?: String;
  helperFunction: Function;
  hideSlashIcon?: boolean;
};

interface IState {
  showDropdown: boolean;
}

export class MenuDropdown extends Component<DropdownProps, IState> {
  state = {
    showDropdown: false
  };

  private myRef = createRef<HTMLDivElement>();

  private clickHandler = (e: any) => {
    const node = this.myRef.current;
    const target = e.target as Element;
    if (!!node) {
      if (!node.contains(target)) {
        this.setState({ showDropdown: false });
      }
    }
  };

  componentDidMount() {
    document.addEventListener("pointerdown", this.clickHandler);
  }

  componentWillUnmount() {
    document.removeEventListener("pointerdown", this.clickHandler);
  }

  public handleToggleDropdown = (pState?: boolean) => {
    if (pState !== undefined) {
      this.setState({ showDropdown: pState });
    }
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  render() {
    const collection = this.props.collection;
    const currentEntryId = this.props.currentEntryId;
    const HelperFunction = this.props.helperFunction;

    return (
      <Frame
        ref={this.myRef}
        style={{ display: collection ? "initial" : "none" }}
      >
        <InnerFrame>
          <HeaderComtainer onPointerDown={() => this.handleToggleDropdown()}>
            <TextContent>
              {collection
                ? collection.find(entry => entry.id === currentEntryId)
                  ? collection.find(entry => entry.id === currentEntryId).name
                  : currentEntryId
                : currentEntryId}
            </TextContent>
            <ChevronRightIcon transform={"rotate(90)"} />
            <SlashIcon
              fill={Colors.navigationHeader.slashColor}
              display={this.props.hideSlashIcon ? "none" : "initial"}
            />
          </HeaderComtainer>
          <NavDropdownContainer>
            <NavDropdown
              className={this.state.showDropdown ? "visible" : "hidden"}
            >
              {collection
                ? collection.map(entry =>
                    currentEntryId === entry.id ? (
                      <ListElem className={"active"} key={entry.id}>
                        <StyledLink
                          key={entry.id}
                          to={`/${HelperFunction(entry.id)}`}
                          className={"active"}
                        >
                          {entry.name}
                        </StyledLink>
                      </ListElem>
                    ) : (
                      <ListElem key={entry.id}>
                        <StyledLink
                          key={entry.id}
                          to={`/${HelperFunction(entry.id)}`}
                        >
                          {entry.name}
                        </StyledLink>
                      </ListElem>
                    )
                  )
                : ""}
            </NavDropdown>
          </NavDropdownContainer>
        </InnerFrame>
      </Frame>
    );
  }
}
export default MenuDropdown;
