import { createGlobalStyle } from "styled-components";
import { theming as Colors } from "../config";
import montserrat from "./fonts/Montserrat-Regular.ttf";
import montserratAlternates from "./fonts/MontserratAlternates-Regular.ttf";
import ibmPlexSans from "./fonts/IBMPlexSans-Regular.ttf";
import ibmPlexMono from "./fonts/IBMPlexMono-Regular.ttf";
const DEFAULT_FONT_SIZE = "16px";

export const GlobalStyle = createGlobalStyle`
     

    @font-face {
      font-family: 'Montserrat';
      src: url(${montserrat});
    }

    @font-face {
      font-family: 'Montserrat Alternates';
      src: url(${montserratAlternates});
    }

    @font-face {
      font-family: 'IBM Plex Sans';
      src: url(${ibmPlexSans});
    }

    @font-face {
      font-family: 'IBM Plex Mono';
      src: url(${ibmPlexMono});
    }
    
      


   body {
      margin:0px;
   }

   *{
    font-family: "IBM Plex Sans","Montserrat","Avenir", "-apple-system", "BlinkMacSystemFont", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
    color: ${Colors.global.fontColors.primary};
  }

  
  pre * {
    font-weight: normal;
    font-family: 'IBM Plex Mono';
  }
  
  
  p {
    
    font-size: 16px;
    line-height: 150%;
    font-size: ${DEFAULT_FONT_SIZE};
    font-style: normal;
    font-weight: normal;
}

 `;
